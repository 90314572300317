import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
import './article_blogs.css';

export const Article_Blogs_n=(props)=>{
    
let title="";
if(props.lsData.length!=0){
title=props.lsData[0]["g_title"];
}
let appPath=appGetUrlPath();
//var listData=JSON.parse(lsData);
var strResp="";
return(<div  class="main-blog-area pt-70px">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="heading page__title-bar">
                    <h3 class="title">{title}</h3>
                </div>
            </div>
        </div>
        <div class="new-product-slider swiper-container slider-nav-style-1">
        <div class="new-product-wrapper swiper-wrapper">
            {/* content part */}
            {props.lsData.map((item,index)=>{
                 var urlTitle= item["col1"];
                 urlTitle=urlTitle.replace("?","_");
                 urlTitle=urlTitle.replace(" ","-");
                 let clpUrl="..\\blog\\"+urlTitle+"?rf="+item["e_id"];
                 if(item["col7"]!==""){
                     clpUrl="..\\"+item["col7"];
                 }
                 if(item["col7"]==="read_now"){
                     //alert("i m in condition "+lsData[i]["col7"]+urlTitle+"?rf="+lsData[i]["e_id"]);
                     clpUrl="..\\"+item["col7"]+"\\"+urlTitle+"?rf="+item["e_id"];
                     //alert(clpUrl);
                 }
                 if(item["col7"].indexOf("://")!==-1){
                    clpUrl=item["col7"];
                 }
                return(<div class="single-blog swiper-slide mt-5">
    <div class="blog-image">
        <a href={clpUrl}><img src={appPath+item["col4"]} class="img-responsive w-100" alt="img_feature"/></a>
    </div>
    <div class="blog-text">
        <h6 class="title"><a class="blog-heading-link" href={clpUrl}>{item["col1"]}</a></h6>
         {/* "<p class=\"blog-detail-text\">"+lsData[i]["col2"]+"</p>"+ */}
        
    </div>
</div>)
            })}
            {/* end of content part */}
</div>
<div class="swiper-buttons">
<div class="swiper-button-next"></div>
<div class="swiper-button-prev"></div>
</div>
</div></div></div>
            )


}

export default Article_Blogs_n;