import React,{useState,useEffect} from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
import {sortListNew} from '../function_lib/common_lib';

export const Article_Listing_n=(props)=>{

  let appUrtPath=appGetUrlPath();

const [listData,setTmpData]=useState([]);
useEffect(()=>{
  if(props.isSort===true){
    let tmpSort=sortListNew(props.lsData,props.sortparam[0],props.sortparam[1],props.sortparam[2]);
    setTmpData(tmpSort);
  }
  
},[props.lsData]);

//alert(JSON.stringify(listData));
let vGtitle="";
if(listData.length>0){
  vGtitle=listData[0]["g_title"];
}
let bgAssign="";

  //alert(JSON.stringify(listData));

    let strResp="";
    let colset="col-lg-3 col-md-6 col-sm-6 col-xs-6";
    let imgProperty="";

    return(<div class={"shop-category-area "+bgAssign+" pb-100px pt-70px"}>
  <div class="container">
      <div class="row">
          <div class="col-lg-12 order-lg-last111 col-md-12 order-md-first">
          <div class="heading page__title-bar">
          <h3 class="title">{vGtitle}</h3>
      </div>
          </div>
          <div class="shop-bottom-area">
          <div class="row">
      {props.lsData.map((item,index)=>{

      if(item["col12"]!==""){
        let bgAssignTmp=item["col12"];
        if(item["col12"]==="default-image"){
          bgAssign="bg-area1";
        }
        
      }
      
      var imgPath="default-product.png"; 
      var urlTitle= item["col1"];
      urlTitle=urlTitle.replace("?","_");
      urlTitle=urlTitle.replace(" ","-");
      if(item["col4"]!=""){
       imgPath=item["col4"];
        }
  
       let landingUrl="blog/"+urlTitle+"?rf="+item["e_id"];
       if(item["col7"]!==""){
         landingUrl="../"+item["col7"];
       }
       if(item["col7"]==="read_now"){
        landingUrl="../"+item["col7"]+"/"+urlTitle+"?rf="+item["e_id"];
       }
       if(item["col7"].indexOf("://")!==-1){
        landingUrl=item["col7"];
       }
       if(item["col7"]==="read_now"){
        //alert("i m in condition "+lsData[i]["col7"]+urlTitle+"?rf="+lsData[i]["e_id"]);
        landingUrl="..\\"+item["col7"]+"\\"+urlTitle+"?rf="+item["e_id"];
        //alert(clpUrl);
      }
       if(item["col13"]!==""){
        colset=item["col13"];
       }
       
       if(item["col10"]!==""){
        
        if(item["col10"]==="round-image"){
          imgProperty="round-image";
        }
       }
       return(<div class={colset}>
      <div class="box-smpad product mr-4 mb-5">
      <form>
            <div class="thumb">
              <a href={landingUrl} class="image">
                  <img src={appUrtPath+imgPath} class={"w-100 "+imgProperty} alt="Product" />
              </a>
          </div>
          <div class="content">
              <h5 class="title"><a href={landingUrl}>{item["col1"]}</a></h5>
          </div>
      </form></div>
  </div>)
      })
    }
       </div></div></div></div></div>
    )

}
export function Article_Listing(lsData){
    var appUrtPath=appGetUrlPath();
   
var listData=lsData;
//alert(JSON.stringify(listData));
let vGtitle="";
if(listData.length>0){
  vGtitle=listData[0]["g_title"];
}
let bgAssign="";

  //alert(JSON.stringify(listData));

    var strResp="";
    let colset="col-lg-3 col-md-6 col-sm-6 col-xs-6";
    let imgProperty="";
  for(var i=0;i<listData.length;i++){
    if(listData[i]["col12"]!==""){
      let bgAssignTmp=listData[i]["col12"];
      if(listData[i]["col12"]==="default-image"){
        bgAssign="bg-area1";
      }
      
    }
    
    var imgPath="default-product.png"; 
    var urlTitle= lsData[i]["col1"];
    urlTitle=urlTitle.replace("?","_");
    urlTitle=urlTitle.replace(" ","-");
    if(listData[i]["col4"]!=""){
     imgPath=listData[i]["col4"];
      }

     let landingUrl="blog/"+urlTitle+"?rf="+lsData[i]["e_id"];
     if(listData[i]["col7"]!==""){
       landingUrl="../"+listData[i]["col7"];
     }
     if(listData[i]["col7"]==="read_now"){
      landingUrl="../"+listData[i]["col7"]+"/"+urlTitle+"?rf="+lsData[i]["e_id"];
     }
     if(lsData[i]["col7"]==="read_now"){
      //alert("i m in condition "+lsData[i]["col7"]+urlTitle+"?rf="+lsData[i]["e_id"]);
      landingUrl="..\\"+lsData[i]["col7"]+"\\"+urlTitle+"?rf="+lsData[i]["e_id"];
      //alert(clpUrl);
    }
     if(listData[i]["col13"]!==""){
      colset=listData[i]["col13"];
     }
     
     if(listData[i]["col10"]!==""){
      
      if(listData[i]["col10"]==="round-image"){
        imgProperty="round-image";
      }
     }
      strResp+="<div class=\""+colset+"\">"+
      "<div class=\"box-smpad product mr-4 mb-5\">"+
      "<form>"+
            "<div class=\"thumb\">"+
              "<a href=\""+landingUrl+"\" class=\"image\">"+
                  "<img src=\""+appUrtPath+imgPath+"\" class=\"w-100 "+imgProperty+"\" alt=\"Product\" />"+
                //   "<img class=\"hover-image\" src=\""+appUrtPath+imgPath+"\" alt=\"Product\" />"+
              "</a>"+
          "</div>"+
          "<div class=\"content\">"+
              "<h5 class=\"title\"><a href=\""+landingUrl+"\">"+listData[i]["col1"]+"</a></h5>"+
            //   "<span class=\"price\">"+
            //       "<span class=\"new\">"+listData[i]["col2"]+"</span>"+
            //   "</span>"+
          "</div>"+
      "</form>"+"</div>"+
  "</div>";
  }
  var strHeader="<div class=\"shop-category-area "+bgAssign+" pb-100px pt-70px\">"+
  "<div class=\"container\">"+
      "<div class=\"row\">"+
          "<div class=\"col-lg-12 order-lg-last111 col-md-12 order-md-first\">"+
          "<div class=\"heading page__title-bar\">"+
          "<h3 class=\"title\">"+vGtitle+"</h3>"+
      "</div>"+
          "</div>"+
          "<div class=\"shop-bottom-area\">"+
          "<div class=\"row\">";
   var strFooter="</div></div></div></div></div>";
  return strHeader+strResp+strFooter;

}
export default Article_Listing_n;