import React,{useEffect} from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
import './collapse_pan.css';

export function Collapse_Pan_Images_n(props){
    /*Header Values */
var title="";
let idM=0;
if(props.lsData.length!=0){
title=props.lsData[0]["g_title"];
idM=props.lsData[0]["id"];
}
useEffect(()=>{

},[props.lsData])

    var appPath=appGetUrlPath();
//var listData=JSON.parse(lsData);
var strResp="";

return(<div id="faq" class="panel-group border-box">
    <div class="container">
    <div class="row">
    <div class="col-md-12 col-xs-12 panel panel-default no-border single-my-account">
            <div class="panel-heading my-account-title">
        <h3 class="panel-title"><a data-bs-toggle="collapse" href={"#my-account-"+idM} class="collapsed" aria-expanded="false">{title}</a></h3>
    </div>
    <div id={"my-account-"+idM} class=" panel-collapse collapse collapsed" data-bs-parent="#faq">
        <div class="row">
        {/* content part */}
        {props.lsData.map((item,index)=>{
             var clpText="know more";
             if(item["col8"]!==""){
                 clpText=item["col8"];
             }
             var clpUrl="";
             if(item["col7"]!==""){
                 clpUrl=item["col7"];
                 clpUrl="<a href="+clpUrl+">"+clpText+"</a>";
             }
            return(
        <div class="product panel-body col-lg-3  col-md-6 col-sm-6 col-xs-6">
            {item["col4"]!==""? <a href={appPath+item["col4"]} class="gallery-item image">
                        {/* <img class="img-thumbnail" src={appPath+item["col4"]} alt="Product" /> */}
                        <img class="img-thumbnail hover-image" src={appPath+item["col4"]} alt="Product" />
                    </a>:''}
        {item["col2"]+clpUrl}
        </div>
    )

        })}
        </div>
        </div>
        </div>
    </div></div>
    </div>
        );

}
export function Collapse_Pan_n(props){
/*Header Values */
var title="";

if(props.lsData.length!=0){
title=props.lsData[0]["g_title"];
}

    var appPath=appGetUrlPath();
//var listData=JSON.parse(lsData);
var strResp="";

return(<div id="faq" class="panel-group border-box">
    <div class="container">
    <div class="row">
        {/* content part */}
        {props.lsData.map((item,index)=>{
             var clpText="know more";
             if(item["col8"]!==""){
                 clpText=item["col8"];
             }
             var clpUrl="";
             if(item["col7"]!==""){
                 clpUrl=item["col7"];
                 clpUrl="<a href="+clpUrl+">"+clpText+"</a>";
             }
             
         
            

            return(<div class="col-md-6 col-xs-6 panel panel-default no-border single-my-account">
            <div class="panel-heading my-account-title">
        <h3 class="panel-title"><a data-bs-toggle="collapse" href={"#my-account-"+item["id"]} class="collapsed" aria-expanded="false">{item["col1"]}</a></h3>
    </div>
    <div id={"my-account-"+item["id"]} class="panel-collapse collapse collapsed" data-bs-parent="#faq">
        <div class="panel-body">
            {item["col4"]!==""?<div class="thumb collapse_pan">
                 <img src={appPath+item["col4"]} alt="blog" />
             </div>:''}
        {item["col2"]+clpUrl}
        </div>
    </div>
</div>)

        })}
    </div></div>
    </div>
        );



}
export default Collapse_Pan_n;